<template>
  <div>
    <slotUI :title="title"></slotUI>
  </div>
</template>

<script>
import slotUI from "@/views/pages/redirect/redirect-slotUI.vue";
import { ApiURL, util } from "@/common/mixins/general.js";

export default {
  mixins: [ApiURL, util],
  components: { slotUI },
  props: {
    id: {
      type: String,
      required: true
    },
    menu: {
      type: String,
      required: true
    },
    delay: Number,

    /**
     * ------------------------------------------------
     * type api menggunakan /hosting/goto atau tools/redirect
     * value "hosting" / "tools"
     * ------------------------------------------------
     */
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      xhr: {
        login: {}
      }
    };
  },
  computed: {
    title: function() {
      return "Redirecting to " + this.menu;
    }
  },
  methods: {
    /**
     * --------------------------------------------------
     * link untuk keperluan redirect login
     * jangan mencoba memindahkan fn ini di redirect-base
     * sudah dicoba hasilnya akan request terus menerus
     * --------------------------------------------------
     */
    get_link_login: function() {
      if (this.type === "hosting") {
        this.link_hosting();
      } else if (this.type === "tools") {
        this.link_tools();
      } else {
        this.mx_redirect({ path: "/dashboard" });
      }
    },

    /**
     * --------------------------------------------------
     * request api dari /hosting/goto jika type=hosting
     * --------------------------------------------------
     */
    link_hosting: function() {
      this.xhr_hosting("goto", `${this.id}/${this.menu}`)
        .then(({ data }) => {
          this.xhr.login = data.data.login;
        })
        .catch(() => {
          this.mx_redirect({ path: "/product/hosting" });
        })
        .finally(() => {
          setTimeout(
            () => {
              this.mx_redirect(this.xhr.login.url, false, false);
            },
            this.delay ? this.delay : 5000
          );
        });
    },

    /**
     * --------------------------------------------------
     * request api dari /hosting/goto jika type=tools
     * --------------------------------------------------
     */
    link_tools: function() {
      this.xhr_tools
        .get(this.api.tools.redirect.index, `?file=${this.menu}&id=${this.id}`)
        .then(({ data }) => {
          this.xhr.login = data.data;
        })
        .catch(() => {
          this.mx_redirect({ path: "/dashboard" });
        })
        .finally(() => {
          setTimeout(
            () => {
              this.mx_redirect(this.xhr.login.url, false, false);
            },
            this.delay ? this.delay : 5000
          );
        });
    }
  },
  mounted() {
    this.get_link_login();
  }
};
</script>
