<template>
  <div>
    <RWCard :title="title ? title : 'Redirecting'">
      <template #body>
        <div class="text-center">
          <i class="fa fa-spin fa-spinner fa-4x"></i>
          <p class="my-3">
            Please wait until the progress is complete. Don't refresh or click
            anything on this page.
          </p>
          <!-- <b-progress :value="100" variant="primary" :animated="true" class="mt-3"></b-progress> -->
        </div>
      </template>
    </RWCard>
  </div>
</template>

<script type="text/javascript">
import RWCard from "@/views/partials/content/Portlet.vue";
export default {
  name: "redirect-slotUI",
  components: { RWCard },
  props: {
    title: {
      type: String
    }
  }
};
</script>
