<template>
  <div>
    <component
      :is="view()"
      :id="id"
      :menu="menu"
      :delay="5000"
      :type="type"
    ></component>
  </div>
</template>

<script>
/**
 * --------------------------------
 * component for menu
 * --------------------------------
 */
import cpanel from "@/views/pages/redirect/menu/redirect-cpanel.vue";
import general from "@/views/pages/redirect/menu/redirect-default.vue";

/**
 * --------------------------------
 * utility
 * --------------------------------
 */
import { breadcrumb, util } from "@/common/mixins/general.js";

export default {
  name: "redirect-base",
  mixins: [breadcrumb, util],
  props: {
    /**
     * ------------------------------------------
     * menu yg akan diredirect eq. cPanel, etc
     * ------------------------------------------
     */
    menu: {
      required: true,
      type: String
    },

    /**
     * ------------------------------------------
     * id layanan dari menu tsb
     * ------------------------------------------
     */
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      /**
       * --------------------------------------
       * untuk membedakan menggunakan API :
       * 1. /domain/hosting
       * 2. /redirect/tools
       * tergantung rest apinya dibuat di hosting atau redirect
       * --------------------------------------
       */
      redirect: {
        hosting: ["Mysql", "WebMail", "CreateEmail", "PhpMyadmin", "cpanel"],
        tools: [
          "manageweebly",
          "rwbackup_login",
          "terimajadi-login",
          "plesk-login"
        ]
      }
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * get view component & validasi menu's
     * -----------------------------------------
     */
    view() {
      // Spread Attributes ...
      let menus = [...this.redirect.hosting, ...this.redirect.tools];
      let check = menus.includes(this.menu);
      if (check) {
        if (this.menu === "cpanel") {
          return (this.component = cpanel);
        } else {
          return (this.component = general);
        }
      } else {
        this.mx_redirect({ name: "dashboard" });
      }
    }
  },
  computed: {
    type: function() {
      if (this.redirect.hosting.includes(this.menu)) {
        return "hosting";
      } else if (this.redirect.tools.includes(this.menu)) {
        return "tools";
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.set_breadcrumb([{ title: "Redirect" }, { title: this.menu }]);
  }
};
</script>
