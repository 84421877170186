<template>
  <div>
    <slotUI title="Redirecting to Control Panel"></slotUI>
    <form :action="action" method="post">
      <input type="hidden" name="user" v-model="user" />
      <input type="hidden" name="pass" v-model="pass" />
      <input type="submit" ref="btnSubmit" hidden="" />
    </form>
  </div>
</template>

<script type="text/javascript">
import slotUI from "@/views/pages/redirect/redirect-slotUI.vue";
import { ApiURL, util } from "@/common/mixins/general.js";
export default {
  name: "redirect-cPanel",
  components: { slotUI },
  mixins: [ApiURL, util],
  props: {
    id: {
      type: String,
      required: true
    },
    menu: {
      required: true,
      type: String
    },
    delay: Number
  },
  data() {
    return {
      action: "",
      user: "",
      pass: ""
    };
  },
  methods: {
    /**
     * --------------------------------------------------
     * link untuk keperluan redirect login
     * jangan mencoba memindahkan fn ini di redirect-base
     * sudah dicoba hasilnya akan request terus menerus
     * --------------------------------------------------
     */
    get_link_login: function() {
      this.xhr_hosting("goto", `/${this.id}/${this.menu}`)
        .then(({ data }) => {
          let form = data.data.login;
          this.action = form.url;
          this.user = form.post.username;
          this.pass = form.post.password;
        })
        .catch(() => {
          this.mx_redirect({ path: "/product/hosting" });
        })
        .finally(() => {
          let self = this;
          setTimeout(
            () => {
              self.$refs.btnSubmit.click();
            },
            this.delay ? this.delay : 5000
          );
        });
    }
  },
  mounted() {
    this.get_link_login();
  }
};
</script>
